<template>
    <div>
        <Header>
            <template v-slot:left></template>
            <template v-slot:mid>
                itrax服务
            </template>
        </Header>
        <div class="col-wrap ">
            <img src="@/assets/mobile/service/banner-bg.jpg" />
            <div class="mask banner">
                <h1>ITRAX SERVICE</h1>
                <h3>数字营销时代的品牌同行者</h3>
                <p>Brand Explorer in the Age of Digital Marketing</p>
            </div>
        </div>
        <div class="col-wrap">
            <img class="service-tit" src="@/assets/mobile/service/service-tit.png" />
            <p style="font-size:12px;color:#666;">
                爱创布局一个内容营销体系，<br />
                两大核心业务模块以及四个创新沟通产品
            </p>
            <ul class="service">
                <li><img src="@/assets/mobile/service/service-2.jpg" /></li>
                <li><img src="@/assets/mobile/service/service-1.jpg" /></li>
                <li><img src="@/assets/mobile/service/service-3.jpg" /></li>
            </ul>
        </div>

        <div class="col-wrap">
            <img class="yingxiao-tit" src="@/assets/mobile/service/yingxiao-tit.png" />
            <ul class="yingxiao">
                <li>
                    <div class="pic">
                        <img src="@/assets/mobile/service/yingxiao-1.jpg" />
                    </div>
                    <div class="content">
                        <h1>内容生成模块</h1>
                        <p>围绕与策略——前置集结媒体沟通， 多维度编辑部式提供深度内容的挖掘和信息体系构建</p>
                    </div>
                </li>
                <li>
                    <div class="pic">
                        <img src="@/assets/mobile/service/yingxiao-2.jpg" />
                    </div>
                    <div class="content">
                        <h1>内容分发模块</h1>
                        <p>以技术赋能的核心架构——基于技术团队的不断研究，持续演进技术产品，全面支持数据挖掘、洞察、促进提升内容高效产出、有效分发等</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-wrap">
            <img class="yewu-tit" src="@/assets/mobile/service/yewu-tit.png" />
            <ul class="yewu">
                <li>
                    <img src="@/assets/mobile/service/yewu-bg-1.png" />
                    <div class="mask">
                        <h1>公关传播</h1>
                        <h3>PR Communication</h3>
                        <h2>
                            致力于为客户提供<br />
                            全价值链的公关传播解决方案
                        </h2>
                        <div></div>
                        <p>品牌公关咨询及传播，产品数字营销及传播，领导人形象建设及体系运营，危机公关咨询及管理，媒体生态建立及维护，事件营销，区域营销，更多...</p>
                    </div>
                </li>
                <li>
                    <img src="@/assets/mobile/service/yewu-bg-2.png" />
                    <div class="mask">
                        <h1>数字营销</h1>
                        <h3>Digital marketing</h3>
                        <h2>
                            致力于为客户量身定制跨平台、<br />
                            多场景的整合数字营销解决方案
                        </h2>
                        <div></div>
                        <p>基于5G时代下的全面社会化沟通渠道运营，双微、短视频平台、知识社区的策略规划及运维，以“中央直播间”为核心模式的企业直播阵地，媒体平台内容分发与自媒体传播，用户运营等...</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-wrap">
            <img class="product-tit" src="@/assets/mobile/service/product-tit.png" />
            <ul class="product">
                <li><img src="@/assets/mobile/service/product-1.jpg" /></li>
                <li><img src="@/assets/mobile/service/product-2.jpg" /></li>
                <li><img src="@/assets/mobile/service/product-3.jpg" /></li>
                <li><img src="@/assets/mobile/service/product-4.jpg" /></li>
            </ul>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Header from "../../components/mobile/header.vue";
import Footer from "../../components/mobile/footer.vue";
export default {
    name: "mobile-service",
    components: { Header, Footer },
    data() {
        return {};
    },
};
</script>
<style scoped>
@import url("../../../common/css/mobile.css");
.product {
    margin: 0 15px 50px 15px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.product li {
    width: 49%;
    margin: 0 0 10px 0;
}
.product-tit {
    width: 56%;
    margin: 40px 0;
}
.yewu-tit {
    width: 32%;
    margin: 30px 0;
}
.yewu {
    margin: 0 15px;
}
.yewu li {
    position: relative;
    margin: 10px 0;
    border-radius: 10px;
    overflow: hidden;
}
.yewu li .mask {
    position: absolute;
    left: 10px;
    top: 0;
    right: 10px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.yewu li .mask h1 {
    font-size: 23px;
    font-weight: normal;
}
.yewu li .mask h3 {
    font-size: 13px;
    font-weight: normal;
    margin-bottom: 10px;
    line-height: 20px;
}
.yewu li .mask h2 {
    font-size: 14px;
    font-weight: normal;
}
.yewu li .mask p {
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    margin: 0 20px;
}
.yewu li .mask div {
    width: 30%;
    border-bottom: 1px #fff solid;
    margin: 20px 0;
}
.yingxiao {
    margin: 20px 15px;
}
.yingxiao li {
    box-shadow: 0 0 6px rgba(221, 219, 219, 0.8);
    display: flex;
    margin: 15px 0;
    border-radius: 6px;
    overflow: hidden;
}
.yingxiao li .pic {
    width: 30%;
    margin: 20px 10px;
}

.yingxiao li .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 15px 10px;
    text-align: left;
}
.yingxiao li .content h1 {
    font-size: 16px;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;
    color: #333333;
}
.yingxiao li .content h1::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 3px #0048b1 solid;
    width: 30px;
}
.yingxiao li .content p {
    font-size: 13px;
    line-height: 28px;
    color: #333333;
}
.yingxiao-tit {
    width: 41%;
    margin: 10px 0;
}
.service {
    margin: 30px 15px;
    display: flex;
    justify-content: space-between;
}
.service li {
    width: 32%;
    border-radius: 10px;
    overflow: hidden;
}
.service-tit {
    width: 60%;
    margin: 20px 15% 0 15%;
}
.banner {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.banner h1 {
    font-size: 20px;
}
.banner h3 {
    font-size: 15px;
    margin: 20px 0;
}
.banner p {
    font-size: 12px;
}
</style>
